html {
  margin: 0;
}

body {
  margin: 0;
  font-family: proxima_nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.4px;
}

body.no-scroll {
  overflow: hidden!important;
}

div {
  box-sizing: border-box;
}

.page {
  max-width: 1280px;
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  .page {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modals {
  max-width: 100%!important;
  padding: 0!important;
  margin: 0!important;
}

label {
  float: left;
}