$black: #383838;
$grey: #ccc;
$grey2: #dadbd9;
$blue: #007bff;
$orange: #fd7e14;
$green: #28a745;  
// new
$light: rgb(246, 243, 241);
$beige: rgb(243, 235, 231);
$beiger: rgb(104, 82, 71);
$fontMain: #383838;
$fontSecond: #1C1C1C;

$max-width: 1280px;