@import './constants.scss';

@media print {
    body {
        padding-top: 0!important;
    }
}

// CONTROL
.btn {
    font-family: monospace;
    padding: 5px 40px;
    height: 50px;

    text-transform: uppercase;
    font-size: 12px;
    line-height: 30px;

    border: 1px solid $beige;
    border-radius: 3px;

    cursor: pointer;

    &:hover, &:active, &:focus {
        outline: 0;
    }

    &.btn-40 {
        height: 40px;
        line-height: 20px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &.btn-slim {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.btn-trans {
        border: none;
        background: transparent;
        color: $fontMain;
        padding-left: 5px;
        padding-right: 5px;
    }

    &.btn-trans-white {
        border: none;
        background: transparent;
        color: $fontMain;
        padding-left: 5px;
        padding-right: 5px;
        color: #FFF;

        &:hover, &:active, &:focus {
            color: rgb(233, 233, 233);
            
            .icon {
                color: #FFF;
            }
        }
    }

    &.btn-primary {
        background-color: $beige;
        color: #FFF;

        &:hover, &:active, &:focus {
            background-color: #FFF;
            color: $beige;
            border-color: $beige;
        }
    }

    &.btn-second {
        background-color: #FFF;
        
        &:hover, &:active, &:focus {
            background-color: $beige;
            color: #FFF;
        }
    }

    &.btn-white {
        background-color: #FFF;
        border-color: $fontMain;
        color: $fontMain;

        &:hover, &:active, &:focus {
            background-color: $grey;
            border-color: $grey;
            color: #FFF;
        }
    }

    &.btn-green {
        background-color: $green;
        border-color: $green;
        color: #FFF;

        &:hover, &:active, &:focus {
            background-color: #FFF;
            color: $green;
            border-color: $green;
        }
    }
}

.ui.red.button, .ui.red.buttons .button {
    background-color: $fontMain!important;
}

a {
    cursor: pointer;
    text-transform: none!important;
    text-decoration: initial;
    color: $fontMain;

    .shop-now, &.shop-now {
        color: $fontSecond;

        &:hover, &:active, &:focus {
            color: darken($grey, 5);
        }
    }
}

// FORM
input[type=text], input[type=email], input[type=password] {
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #CCC;
    background-color: #FFF;
}

textarea {
    box-sizing: border-box;
}

// LOGO
.logo-text {
    color: white;
    letter-spacing: 2px;
    user-select: none;

    .line1 {
        font-size: 38px;
        font-weight: bolder;
        letter-spacing: 3px;
        color: $black;
    }

    .line2 {
        font-size: 12px;
        text-transform: uppercase;
        color: $orange;
        padding-bottom: 5px;
        font-weight: 600;
    }

    .line3 {
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        color: $fontSecond;
        padding-bottom: 5px;
    }
}

// ALERT
.error-msg {
    color: $fontMain;
    font-weight: 700;
    text-decoration: underline;
}

.success-msg {
    color: $green;
}

.warning-msg {
    color: $fontMain;
    font-weight: 500;
    text-decoration: underline;
}

// GENERAL
.flex-div {
    display: flex;
    justify-content: center;
}

.flex-only {
    display: flex;
}

.align-center {
    align-items: center;
}

.flex-left {
    display: flex;
    justify-content: left;
}

.flex-right {
    display: flex;
    justify-content: right;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.width-100 {
    width: 100%;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-50-sm {
    @media screen and (min-width: 768px) {
        margin-top: 50px;
    }
}

.margin-left-20 {
    margin-left: 20px;
}

.padding-10 {
    padding: 10px;
}

.cursor {
    cursor: pointer;
}

.border-radius {
    border-radius: 5px;
}

.fontMain {
    color: $fontMain;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.strong-text {
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    .hidden-xs {
        display: none!important;
    }
}

@media screen and (min-width: 768px) {
    .show-xs {
        display: none!important;
    }
}

.background-white {
    background-color: #fff!important;
}

.background-grey {
    background-color: $grey!important;
}

.ui.dimmer {
    position: fixed;
}

.ui.pagination.menu .item {
    justify-content: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}