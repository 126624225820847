@import '../../../../style/constants';

#preview {
    margin-top: 20px;

    .control-line {
        width: 100%;
        padding: 20px 0;

        .control {
            padding: 10px 20px;
            cursor: pointer;
        }
    }

    .preview-body {
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .preview-col {
        width: 49%;
        max-width: 500px;

        @media screen and (max-width: 768px) {
            width: 100%!important;
            margin-bottom: 10px;
        }

        textarea.json {
            min-height: 500px;
            width: 100%;
        }
    }

    .question-preview {
        border: 1px solid black;
        .question {
            padding: 10px;

            .title {
                font-size: 20px;
                padding: 20px;
            }
            .question-chunks {
                padding: 10px;
                display: flex;
                justify-content: space-between;

                .image {
                    width: 30%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                }

                .chunks {
                    width: 60%;
                    text-align: left;
                }
            }
        }

        .answer {
            .options {
                padding: 10px;
                display: flex;
                justify-content: center;
                flex: 1;
                flex-wrap: wrap;

                .option {
                    width: 90%;
                    height: 40px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    border: 1px solid black;
                    border-radius: 5px;
                    margin-bottom: 3px;
                }

                .select-word {
                    padding: 5px;
                    border: 1px solid black;
                    border-radius: 5px;
                }
            }

            .check-button {
                width: 90%;
                height: 40px;
                margin: 20px 0;
                border-radius: 5px;
            }

            textarea {
                width: 90%;
            }
        }
    }
}