@import '../../../../style/constants.scss';

#question-form {
  h2 {
    color: $orange;
  }

  .toggle-text-container {
    display: flex;

    .toggle-text-box {
      padding: 10px 2px;
    }

    .toggle-text {
      cursor: pointer;
  
      &.selected {
        background: $blue!important;
      }
    }
  }

  .delete-item {
    cursor: pointer;
    color: $orange;

    &:hover {
        text-decoration: underline;
    }
  }

  .check-item {
    cursor: pointer;
    color: $green;

    &:hover {
        text-decoration: underline;
    }
  }
}